// Import React
import React, {useState, useEffect} from 'react';

// Import gatsby
import {navigate} from "gatsby"

// Import Firebase
import {auth, firestore} from '../../utils/firebase';

// Import Material UI
import Grow from '@material-ui/core/Grow';
// import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Dialog from '@material-ui/core/Dialog';
// import TextField from '@material-ui/core/TextField';
// import VpnKeyIcon from '@material-ui/icons/VpnKey';
// import FormControl from '@material-ui/core/FormControl';
// import FormHelperText from '@material-ui/core/FormHelperText'
// import Input from '@material-ui/core/Input'
// import InputLabel from '@material-ui/core/InputLabel';

// Import components
import Layout from "../../components/layout";
import SEO from "../../components/seo"

import MenuAppBar from '../../components/WidgetComponents/AppBar'

// Make Styles
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '',
    height: '98vh',
    marginTop: '1vh',
    width: '99vw',
    padding: '5vh 8vw'
  },
  name: {
    textAlign: 'center',
    fontWeight: '700',
    fontFamily: 'Work Sans',
    padding: '0px 20px',
    fontSize: '50px',
    lineHeight: '1.1',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '5vw',
      padding: '0px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '8vw',
      padding: '0px'
    }
  },
  title: {
    textAlign: 'center',
    fontWeight: '300',
    fontFamily: 'Work Sans',
    padding: '30px 20px',
    fontSize: '35px',
    lineHeight: '1.1',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '3vw',
      padding: '30px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '6vw',
      padding: '20px'
    }
  },
  description: {
    fontFamily: 'Work Sans',
    textAlign: 'center',
    fontWeight: '500',
    lineHeight: '1.1',
    fontSize: '40px',
    padding: '0.5vw 3vw',
    letterSpacing: '-1px',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '3vw',
      padding: '0.5vw 2vw',
      // padding: '5px 5px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '7vw',
      padding: '1vw 3vw'
    }
  },
  credit: {
    fontFamily: 'Work Sans',
    textAlign: 'center',
    fontWeight: '200',
    lineHeight: '1.3',
    fontSize: '2vw',
    padding: '12px 20px 5px',
    color: '#6e6e6e',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '3vw'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '4.5vw'
    }
  },
  dialog: {
    padding: '40px',
    textAlign: 'center',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      padding: '20px'
    }
  },
  button: {
    margin: '8px',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      margin: '5px'
    }
  },
  joined: {
    textAlign: 'center',
    fontWeight: '300',
    fontFamily: 'Work Sans',
    padding: '0px 0px 0px',
    fontSize: '2vw',
    lineHeight: '1.1',
    letterSpacing: '-1px',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '2vw',
      padding: '30px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '6vw',
      padding: '10px'
    }
  }
}))

// Transitions
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction='up' ref={ref} {...props}/>;
// });

// Page
function SignedIn() {

  // Use Styles
  const classes = useStyles();

  // State
  const [activeUser, setActiveUser] = useState(undefined);
  const [name, setName] = useState('');
  const [photoURL, setPhotoURL] = useState(undefined);
  const [joinDate, setJoinDate] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  // const [dialogIsOpen, setDialogIsOpen] = useState(false);

  // Get Firebase
  // const firebase = getFirebase();

  // Effect Hook

  useEffect(() => {
    // if (!firebase)
    //   return;
    if (isLoaded)
      return;
    auth
      .onAuthStateChanged((user) => {
        if (user) {
          // alert('User is signed in')
          // setIsSignedIn(!!user);
          setActiveUser(user)
          const db = firestore
          let docRef = db
            .collection("users")
            .doc(user.uid);
          docRef
            .get()
            .then(function(doc) {
              if (doc.exists) {
                console.log('yeet')
                let joinDate = doc.data()['joinDate']
                setJoinDate(joinDate.slice(4, 15))
                setPhotoURL(activeUser.photoURL);
                setName(`${activeUser.displayName}`);
                setIsLoaded(true);
              } else {
                // doc.data() will be undefined in this case
                navigate('/signedin')
              }
            })
            .catch(function(error) {
              console.log("Error getting document:", error);
            });
        } else {
          navigate('/')
        }
      });
  });

  // Functions
  // const handleSignOut = () => {
  //   firebase
  //     .auth()
  //     .signOut();
  //   navigate('/signedout')
  // };
  // const openUnlockDialog = () => {
  //   setDialogIsOpen(true);
  // };
  // const closeDialog = value => {
  //   setDialogIsOpen(false);
  // };

  function goToDashboard() {
    navigate('/my/dashboard')
  }

  // Conditionals
  const profileImage = (
    photoURL
    ? <img src={photoURL} alt='Profile' width='150px' height='150px' style={{
        borderRadius: '150px',
        marginBottom: '-2px',
        margin: '15px'
      }}/>
    : undefined)

  // Components
  const title = (<Grid item="item">
    <Typography variant='h3' className={classes.title}>My Profile</Typography>
    <Typography variant='h3' className={classes.name}>{name}</Typography>
  </Grid>)
  const description = (<Grid item="item">
    <Typography variant='h4' className={classes.joined}>Joined</Typography>
    <Typography variant='h4' className={classes.description}>{joinDate}</Typography>
  </Grid>)
  const buttons = (<Grid item="item">
    <Grid container="container" justify="space-evenly" alignItems="center">
      <Button size='large' color="secondary" variant='contained' onClick={goToDashboard} className={classes.button}>Go to Dashboard</Button>
    </Grid>
  </Grid>)

  // Final Page
  const loading = <Grid container="container" direction="column" justify="center" alignItems="center" className={classes.root}>
    <CircularProgress/>
  </Grid>
  const loadedPage = <Grid container="container" className={classes.root}>
    <Grid container="container" direction="column" justify="center" alignItems="center" spacing={3}>
      {title}
      {profileImage}
      {description}
      {buttons}
    </Grid>
  </Grid>

  // Render
  return (<Layout>
    <SEO title="My Profile"/>
    <MenuAppBar/>
    <Grow in="in">
      {
        isLoaded
          ? loadedPage
          : loading
      }
    </Grow>
  </Layout>)
}

export default SignedIn
